/* html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  overflow: auto;
}

body {
  padding: 1rem;
  overflow: auto;
} */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

:root,
body {
  padding: 0;
  margin: 0;
}

#root {
  display: grid;
  grid-template-columns: auto 1fr;
  font-family: 'Malgun Gothic';
}

.App {
  display: grid;
  grid-template-columns: auto 1fr;
}

.AppMain {
  display: grid;
  grid-template-columns: auto 1fr;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  block-size: 100vh;
  padding: 8px;
  overflow: auto;
}

.tabHeader {
  position: sticky;
  top: 0;
  margin-top: 1px;
  margin-bottom: 5px;
}

.tabContent {
  overflow: auto;
  height: calc(100vh - 30px);
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-block-end: 8px;
}

.dialogContainer {
  position: absolute;
  inset: 0;
  display: flex;
  place-items: center;
  background: rgba(0, 0, 0, 0.1);

  > dialog {
    width: 300px;
    > input {
      width: 100%;
    }

    > menu {
      text-align: end;
    }
  }
}

.rowjh {
  background-color: #9370db;
  color: white;

  &:hover {
    background-color: #800080;
  }
}
.ag-header-cell-label {
  justify-content: center;
}
.ps-menu-button {
  height: 32px !important;
}

.cell-span {
  background: white;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}