.ag-theme-alpine {
  --ag-font-size: 11px !important;
  --ag-font-family: 'Malgun Gothic';
  --ag-selected-row-background-color: rgba(21, 51, 21, 0.2) !important;
  --ag-odd-row-background-color: #f3f3f3 !important;
}

.ag-theme-balham {
  --ag-font-size: 12px !important;
  --ag-font-family: 'Malgun Gothic';
  --ag-row-hover-color: rgba(33, 150, 243, 0.2) !important;
  --ag-selected-row-background-color: rgb(0, 255, 0, 0.2) !important;
}

.ag-edited {
  background-color: rgb(0, 255, 0, 0.2) !important;
}